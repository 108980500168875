import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We are in unprecedented times – a global pandemic is affecting all aspects of our daily lives.`}</p>
    <p>{`As people and businesses embrace social distancing, there is a growing need to interact where people can no longer be present, or fewer people may be present, given layoffs, social distancing, and shelter-in-place rules.`}</p>
    <p>{`At Dashbot, we are seeing, firsthand, an increased interest in chatbots from enterprises looking to provide customer support and services to consumers.`}</p>
    <p>{`At the same time, we are seeing quite a bit of Coronavirus related messages from end-users to chatbots.`}</p>
    <p>{`We examined the past few months of data sent to chatbots on our platform. Having processed over 86 billion messages, Dashbot sees quite a bit of data, across a wide variety of platforms and use-cases.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/04/13095440/covid-weekly.png",
        "alt": null
      }}></img>{` Nearly a quarter of all chatbots on our platform have received a Coronavirus related message in the past month. As early as January, end-users were messaging chatbots about Coronavirus – about 2% received a related message. The percentage slowly increased in February, and then spiked up substantially at the beginning of March. The percentage appears to be plateauing in April.`}</p>
    <p>{`While we see Coronavirus related messages across all platforms, we took a closer look at some of the more popular ones – Alexa, Facebook, Google, and “generic” web-based chatbots.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/04/13095502/covid-weekly-platforms.png",
        "alt": null
      }}></img>{` More web-based chatbots tend to receive Coronavirus related messages compared to other platforms. These chatbots tend to be more support related – enterprise customer service or internal support chatbots.`}</p>
    <p>{`However, it is interesting to see Alexa Skills getting Coronavirus related messages, given how they work. Alexa does not provide the raw utterance to developers – only the Intent and attributes. For example, if a user asks an Alexa Skill “What is the weather in San Francisco?” The developer would receive the Intent, “check weather,” and the slot-parameter, “location=San Francisco,” not the full statement said.`}</p>
    <p>{`In order for an Alexa Skill to receive a Coronavirus related message, the Skill needs to be configured to accept slot parameter attributes, and the user has to say something that matches the format of the Intent. Given the wide variety of Alexa use-cases, this leads to some interesting, and at times amusing, requests to Skills – especially in the entertainment category.`}</p>
    <p>{`We also examined the more common Coronavirus related messages sent to chatbots over the past few months. The top messages have changed a bit over time from questions about the virus, to more simpler one-word messages involving the virus.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/04/13095407/covid-messages-updated.png",
        "alt": null
      }}></img>{` These Coronavirus related messages are being sent to all types of chatbots – a wide variety of use-cases. Not only are customer service, travel, financial, insurance, and health and wellness chatbots receiving these messages, but we see them in education, lifestyle, entertainment, automotive, and so much more.`}</p>
    <div className="wp-block-image">
  <figure className="aligncenter is-resized">
    ![](https://i.dashbot.io/wp-home/uploads/2020/04/12183706/covid-categories.png)
  </figure>
    </div>
It was interesting to see through the data, end-users early interest and
questions about the disease.
    <p>{`While the current situation may not be positive, we are optimistic about the future. If there is a silver lining in the current pandemic, it is seeing the whole world coming together through efforts like sheltering in place. We look forward to the brighter days ahead.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is an analytics platform for conversational interfaces that enables enterprises to increase engagement, satisfaction, and conversion through actionable insights and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we provide chatbot specific analytics including NLP response effectiveness, sentiment analysis, conversation flows, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our NLP Optimization tools, Phrase Clustering, NLP Assessments, and Live Person Take Over of chat sessions.`}</p>
    <p>{`We support Alexa, Google Assistant, Web Chat, SMS, Facebook Messenger, Slack, Twitter, Bixby, and any other conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      